import { Injectable } from '@angular/core'
import {
  AggregatorType,
  IPaginationFilteredRequest,
  ITableAction,
  ITableFIlterNotifier,
  ITableFilterRule,
  TableColumnType,
  TableFilters,
  TableOperator
} from './table-filter.interface'
import { DateService, environment } from '@mg-platform/core/core-util'
import { isArray } from 'lodash'
import { PeriodType } from '@mg-platform/core/core-data-access'

@Injectable({
  providedIn: 'root'
})
export class TableActionsService {
  constructor(private dateService: DateService) {}

  createRequestPayload(info: ITableAction): IPaginationFilteredRequest {
    const paylad: IPaginationFilteredRequest = {
      filters: {},
      top: environment.pageSize,
      skip: (info.page - 1) * environment.pageSize
    }

    if (info?.sort && info.sort.active) {
      paylad['orderBy'] = [
        {
          direction: info.sort.direction === 'asc' ? 'Ascending' : 'Descending',
          property: info.sort.active
        }
      ]
    }

    paylad.filters = Object.fromEntries(
      Object.keys(info?.filters ?? [])
        .map((el) => info?.filters?.[el])
        .filter((el) => el?.items && el.items.length > 0)
        .map((el) => {
          if (!el) {
            return []
          }
          let aggregator = el.aggregator
          let items: ITableFilterRule[] | undefined
          switch (el.columnType) {
            case TableColumnType.checkbox:
              if (isArray(el.items?.[0].value)) {
                items = (el.items[0].value ?? []).map((value: string) => ({
                  operator: TableOperator.Equals,
                  value
                }))
              } else {
                items = []
              }
              break

            case TableColumnType.date:
              aggregator = AggregatorType.And
              items = el.items?.map((el) => ({
                operator: el.operator,
                value: el.value ?? PeriodType.Custom,
                extraValue: {
                  from: this.dateService.momentToDateString(el.extraValue.from),
                  to: this.dateService.momentToDateString(el.extraValue.to)
                }
              }))
              break

            default:
              items = el.items?.map((el) => ({
                operator: el.operator,
                value: `${el.value}`
              }))
              break
          }
          return [
            el.key,
            {
              key: el.key,
              aggregator,
              items
            }
          ]
        })
    )

    return paylad
  }

  prepareChangedFilters(payload: {
    key: string
    info: ITableFIlterNotifier
    tableActions: ITableAction
    tableFilters: TableFilters
  }): ITableAction | undefined {
    const { key, info, tableActions, tableFilters } = payload
    if (!key || key === '') {
      return
    }
    if (info.shouldDelete) {
      tableFilters[key].items = undefined
      tableFilters[key].aggregator = undefined
    }
    if (info.filter) {
      tableFilters[key] = { ...info.filter }
    }
    if (info.sort) {
      tableActions.sort = info.sort
    } else {
      tableActions.page = 1
    }
    return { ...tableActions, filters: tableFilters }
  }
}
